import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCardContent: {
      styleOverrides: { root: { border: '1px solid rgba(0, 88, 130, 0.10)' } },
    },
    MuiIconButton: { styleOverrides: { root: { color: '#142446' } } },
    MuiIcon: { styleOverrides: { colorPrimary: '#142446' } },
  },
  palette: {
    primary: { main: '#142446' },
    secondary: { main: '#dcbd84' },
    mode: 'light',
  },
});

export default theme;
