import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Hidden,
  Link,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import logotipo from 'assets/images/logotipo.jpg';
import useStyles from './styles';

// const pages = ['Sobre o Escritório', 'Advogado', 'Áreas de Atuação', 'contato'];
const pages = [
  { title: 'Quem Sou', anchor: 'descriptionLawyer' },
  { title: 'Áreas de Atuação', anchor: 'areasOfExpertise' },
  { title: 'Escritório', anchor: 'descriptionOffice' },
  { title: 'Contato', anchor: 'contato' },
];

const ResponsiveAppBar = () => {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Hidden mdDown>
            <img
              src={logotipo}
              className={classes.logo}
              alt="logo Augusto Teixeira"
            />
          </Hidden>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color="secondary" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className={classes.menu}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ title, anchor }) => (
                <MenuItem
                  key={title}
                  onClick={handleCloseNavMenu}
                  href={`#${title}`}
                >
                  <Typography textAlign="center">
                    <Link href={`#${anchor}`} className={classes.link}>
                      <div className={classes.linkText}>{title}</div>
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Hidden mdUp>
            <Box sx={{ mr: 1 }}>
              <img
                src={logotipo}
                className={classes.logResponsive}
                alt="logo Augusto Teixeira"
              />
            </Box>
          </Hidden>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ anchor, title }) => (
              <Button
                key={title}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                href={`#${anchor}`}
              >
                {title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
